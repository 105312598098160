import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Carouselietms from '../Carouselietms';
import Footer from '../Footer';





function Home () {
    return (
        <>
         <head>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-24SVZDDELW"></script>
                <script dangerouslySetInnerHTML={{
                    __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());

                    gtag('config', 'G-24SVZDDELW');`
                }}>
                
                </script>
        </head>
            <HeroSection/>
            <Cards/>
            <Carouselietms/>
            <Footer/>
            
        </>

    );
}

export default Home;