import React from 'react';
import './Products.css';
import '../Footer.css';
import Footer from '../Footer';

function Products() {
    return (
        <div className="products-container">
            <h1 className="products-title">הגיע הזמן להתנסות במשהו חדש</h1>
            <div className="products-grid" dir='rtl'>
                {/* Add your images here */}
                
                <div className="product-card">
                    <img src="images/IMG_7024.jpeg" alt="Product 1" />
                    <p>מחליק פלולס מגיע עד 240 מעלות עוצמתי במיוחד בעל פלטות טיטניום איכותיות</p>
                </div>
                <div className="product-card">
                    <img src="images/IMG_7023.jpeg" alt="Product 1" />
                    <p>לק בלאש - עדה לזורגן סומק נוזלי לקבלת מראה שזוף וזוהר</p>
                </div>
                <div className="product-card">
                    <img src="images/newproduct1.jpeg" alt="Product 1" />
                    <p>מחליק רחב flawless בעל פלטות רחבות עשויות טיטניום איכותי מתאים להחלקות</p>
                </div>
                <div className="product-card">
                    <img src="images/newproduct2.jpeg" alt="Product 1" />
                    <p>מסכה מועשרת בקרטין ללא מלחים מתאימה גם לשיער שעבר החלקה</p>
                </div>
                <div className="product-card">
                    <img src="images/newproduct3.jpeg" alt="Product 1" />
                    <p>שמפו מועשר בקרטין ללא מלחים מתאים גם לשיער שעבר החלקה</p>
                </div>
                <div className="product-card">
                    <img src="images/IMG_0478.jpeg" alt="Product 1" />
                    <p>מסלסל שיער 3 ראשים מתחלפים מבית ווטליין פרו בעל ציפוי טורמלין</p>
                </div>
                <div className="product-card">
                    <img src="images/ex4.jpeg" alt="Product 1" />
                    <p>ווקס שקוף לגבות עמיד במיוחד, מכיל קראטין וחומרים המועילים לחיזוק סיב השיערה + מברשת לעיצוב הגבות</p>
                </div>
               
               
                <div className="product-card">
                    <img src="images/product2.jpeg" alt="Product 1" />
                    <p>KAMI - מברשת מחליקה לסלסול והחלקת השיער לשימוש על שיער יבש</p>
                </div>
                <div className="product-card">
                    <img src="images/product4.jpeg" alt="Product 1" />
                    <p>KAMI - מברשת פן לייבוש החלקה ועיצוב השיער + KAMI מחליקה להחלקה וסלסול השיער לשימוש על שיער יבש (המברשות נמכרות בנפרד)</p>
                </div>
                {/* Repeat for other products */}
            </div>
            <div className='products-footer'>
            <Footer/>

            </div>
           
        </div>
    );
}


export default Products;
