import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from './components/pages/Home';
import Products from './components/pages/Products';
import HairCatalog from './components/pages/HairCatalog';
import AboutUs from './components/pages/AboutUs';

function App() {
  return (
    <>
    <head>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-24SVZDDELW"></script>
          <script dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-24SVZDDELW');`
          }}>
           
          </script>
    </head>
    <Router>
    <Navbar />
      <Routes>
        <Route path='/' exact Component={Home} />
        <Route path='/מוצרים' exact Component={Products} />
        <Route path='/תסרוקות' exact Component={HairCatalog} />
        <Route path='/שירותים' exact Component={AboutUs} />
        <Route path="*" element={<div>Not Found</div>} />
        
      </Routes>
    
      
   
    </Router>
    </>
     
    
  );
}

export default App;


