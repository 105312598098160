import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import './Navbar.css';
import Products from './pages/Products';
import HairCatalog from './pages/HairCatalog';
import AboutUs from './pages/AboutUs';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true)
  
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false)

  const showButton = () => {
    if(window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect( ()=> {
    showButton() 
  }, []);

  window.addEventListener('resize', showButton);
  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
            <Link to='/' className="navbar-logo" onClick={closeMobileMenu}>
                <img src="/images/image_6483441_clipdrop-background-removal.png" alt="Logo" className='navbar-logo-image'/>
            </Link>
        
            {/* {button && <Button buttonStyle='btn--outline'>להרשמה</Button>} */}
        </div>

        <div className='menu-icon' onClick={handleClick}>
              <i className= "fas fa-bars" />
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                <li className='nav-item close-icon'>
                    <i className="fas fa-times" onClick={closeMobileMenu}></i>
                </li>

                <li className='nav-item'>
                <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                  עמוד הבית
                </Link>
              </li>
              
              <li className='nav-item'>
                <Link to='/תסרוקות' className='nav-links' onClick={HairCatalog}>
                  תסרוקות
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/מוצרים' className='nav-links' onClick={Products}>
                  מוצרים
                </Link>
              </li>
              <li className='nav-item'>
                <Link to='/שירותים' className='nav-links' onClick={AboutUs}>
                  שירותים
                </Link>
              </li>
              
              
            </ul>
      </nav>
    </>
  );
}


export default Navbar
