import React, { useEffect, useRef, useState } from 'react';
import './Carouselitems.css';

export default function Carouselietms() {
    const boxRef = useRef(null);
    const [enlargedImage, setEnlargedImage] = useState(null);

    useEffect(() => {
        let rotationDegree = 0;
        const rotationInterval = setInterval(() => {
            rotationDegree += 0.6; // You can adjust this value for faster or slower rotation
            boxRef.current.style.transform = `perspective(1000px) rotateY(${rotationDegree}deg)`;
        }, 30); // This rotates the gallery every 30ms

        return () => {
            clearInterval(rotationInterval);
        };
    }, []);

    const handleImageClick = (image) => {
        if (enlargedImage === image) {
            setEnlargedImage(null); // Close the enlarged image if it's clicked again
        } else {
            setEnlargedImage(image); // Enlarge the clicked image
        }
    };

    const images = [
        'images/tisroket1.jpeg',
        'images/tisroket2.jpeg',
        'images/tisroket3.jpeg',
        'images/tisroket4.jpeg',
        'images/tisroket5.jpeg',
        'images/tisroket6.jpeg',
        'images/tisroket7.jpeg',
        'images/tisroket8.jpeg',
    ];

    return (
        <div>
            <h1 className='h1-title'>התסרוקות שלנו</h1>
            <div className="box" ref={boxRef}>
                {images.map((image, index) => (
                    <span key={index} style={{ '--i': index + 1 }} onClick={() => handleImageClick(image)}>
                        <img src={image} alt={`Item ${index + 1}`} style={enlargedImage === image ? { transform: 'scale(2)' } : {}} />
                    </span>
                ))}
            </div>
            {enlargedImage && (
                <div className="enlarged-image-container" onClick={() => setEnlargedImage(null)}>
                    <img src={enlargedImage} alt="Enlarged" />
                </div>
            )}
        </div>
    );
}
