import React from 'react';
import Carousel from 'react-grid-carousel'
import './HairCatalog.css';
import '../Footer.css';
import Footer from '../Footer';
import { useState } from 'react';

function HairCatalog() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');

  const imageDetails = [
    { image: "images/tisroket1.jpeg", text: "4 צמות" },
    { image: "images/tisroket2.jpeg", text: "צמות מהרטה" },
    { image: "images/tisroket3.jpeg", text: "צמות מהרטה" },
    { image: "images/tisroket4.jpeg", text: "6 צמות " },
    { image: "images/tisroket5.jpeg", text: "2 צמות עם תוספות" },
    { image: "images/tisroket6.jpeg", text: "מחליק תלתל" },
    { image: "images/tisroket7.jpeg", text: "2 צמות עם תוספות" },
    { image: "images/tisroket8.jpeg", text: "מחליק" },
    { image: "images/tisroket9.jpeg", text: "מחליק" },
    // { image: "images/tisroket10.jpeg", text: "Text for Image 2" },
   
    { image: "images/tisroket12.jpeg", text: "2 צמות ומחליק תלתל" },
    { image: "images/tisroket13.jpeg", text: "מחליק תלתל ומתיחה בצד" },
    { image: "images/tisroket14.jpeg", text: "מוברש ושתי צמות" },
    { image: "images/tisroket15.jpeg", text: "מוברש ושתי צמות" },
    { image: "images/tisroket16.jpeg", text: "מוברש ושתי צמות" },
    { image: "images/tisroket17.jpeg", text: "2 צמות" },
    { image: "images/tisroket18.jpeg", text: "מוברש" },
    { image: "images/tisroket19.jpeg", text: "קוקו מוברש" },
    { image: "images/tisroket20.jpeg", text: "מוברש" },
    { image: "images/tisroket21.jpeg", text: "מוברש" },
    { image: "images/tisroketex.jpeg", text: "קוקו חלק" },
    { image: "images/tisroket23.jpeg", text: "קוקו אסוף נמוך" },
    { image: "images/tisroket24.jpeg", text: "מחליק תלתל ושתי צמות" },
    { image: "images/tisroket25.jpeg", text: "6 צמות" },
    { image: "images/tisroket26.jpeg", text: "6 צמות" },

    // ... add more image details here
  ];

  return (
    <div className="HairCatalog-title">
      <h1>תסרוקות</h1>
      <div className="image-grid">
        {imageDetails.map((detail, index) => (
          <div key={index} className="image-item">
            <img src={detail.image} alt={`Hair style ${index + 1}`} onClick={() => {
              setModalImage(detail.image);
              setIsModalOpen(true);
            }} />
            <span dir='rtl' className="image-text">{detail.text}</span>
          </div>
        ))}
      </div>
      <div className='haircatalog-footer'>
        <Footer/>
      </div>
      <ImageModal
        isOpen={isModalOpen}
        imageSrc={modalImage}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );

  function ImageModal({ isOpen, imageSrc, onClose }) {
    if (!isOpen) return null;

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 1000,
        }}
        onClick={onClose}  // Close the modal when the background is clicked
      >
        <img 
          src={imageSrc} 
          alt="Enlarged" 
          style={{ maxWidth: '50%', maxHeight: '50%' }} 
          onClick={onClose} // Close the modal when the image is clicked
        />
      </div>
    );
  }
}

export default HairCatalog;
