import React from 'react';
import './AboutUs.css';
import '../Footer.css';
import Footer from '../Footer';
const AboutUs = () => {
    return (
        <>
        <div className="about-us-container">
            <h1 className="about-us-title">אודותינו</h1>
            <p className="about-us-description">
            StraightHair
            המקום בו השיער שלכן הופך לאומנות. כאן, 
            אנו מתמחות ביצירת תסרוקות מרשימות ומציעות לכן את המוצרים הטובים ביותר לשיער.
            התשוקה שלנו היא להביא את היופי הטבעי שבכן לבחוץ ולהפוך את השיער שלכן לשיא היופי שלו.
            אנו גאות במוצרים שלנו ובשירות הלקוחות המקצועי שלנו, הצטרפו אלינו למסע של יופי וביטחון.
            במהלך השנים, צברנו ניסיון רב והבנה עמוקה לצרכים של הלקוחות שלנו.
            אנו מבינות את החשיבות שבאיכות, ולכן אנו מבטיחות לספק רק את הטוב ביותר.
            האמון שלכן בנו הוא המניע הגדול ביותר להמשך הצמיחה וההתפתחות שלנו.
            </p>
            {/* Optional: Add an image or background related to hair straighteners */}
            { <img src="images/aiimage4new (2).jpeg" alt="Hair straightening" className="about-us-image" /> }

            
           
        </div>
        <div className='AboutUs-footer'>
            <Footer/>
        </div>
        </>
        
       
    );
}

export default AboutUs;
