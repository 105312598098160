import React from 'react'
import { Button } from './Button'
import '../App.css';
import './HeroSection.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
function HeroSection() {
  const isMobile = window.innerWidth <= 768;

  const handleTrailerClick = (event) => {
    event.preventDefault();
    window.open("https://schedule.easybizy.net/straighthair?fbclid=PAAaYTQ86Gfbfkh-2-NXCLD7hvnkU4EYCvVraBUq077Ni0Ynp-OSfa0tMUC6c_aem_ARnpT1Vh3Ba03WLk0QA3rCH6_EJF7phGRNQSIv1wOOBxsXa4kaplo6t20QPhcTQ0nnE", "_blank");
};


  return (
    <div className='hero-container'>
        <video src="/videos/cover-video.MP4" autoPlay loop muted />
        <h1>אל תוותרי על המראה המושלם</h1>
        <p>!גלי את הקסם שלנו</p>
        <div className='hero-btns' dir='rtl'>
            {/* <Button className='btns' buttonStyle='btn--outline' buttonSize={isMobile ? 'btn--medium' : 'btn--large'}>
                GET STARTED
            </Button> */}
            <Button className='btns' buttonStyle='btn--primary' buttonSize={isMobile ? 'btn--medium' : 'btn--large'} onClick={handleTrailerClick}>
                לזימון תורים <FontAwesomeIcon icon={faCalendarCheck} />
            </Button>
        </div>
    </div>
  )
} 


export default HeroSection
