import React from 'react'
import CardItem from './CardItem'
import './Cards.css'

function Cards() {
  return (
    <div className='cards'>
        <h1 className='h1-title'>שיער חלק ובריא הוא לא רק חלום - התנסי בסדרת הטיפוח החדשה שלנו</h1>
        <div className='cards__container'>
            <div className='cards__wrapper'>
                <ul className='cards__items' dir='rtl'>
                    <CardItem 
                      src="images/product2.jpeg"
                      text='KAMI - מברשת מחליקה לסלסול והחלקת השיער לשימוש על שיער יבש'
                      label="מחליקים"
                      path='/מוצרים'
                    />
                     <CardItem 
                      src="images/IMG_0478.jpeg"
                      text='מסלסל שיער 3 ראשים מתחלפים מבית ווטליין פרו בעל ציפוי טורמלין'
                      label="מחליקים"
                      path='/מוצרים'
                    />
                  
                    <CardItem 
                      src="images/IMG_7023.jpeg"
                      text='לק בלאש - עדה לזורגן סומק נוזלי לקבלת מראה שזוף וזוהר'
                      label="מחליקים"
                      path='/מוצרים'
                    />
                     <CardItem 
                      src="images/newproduct1.jpeg"
                      text='מחליק רחב flawless בעל פלטות רחבות עשויות טיטניום איכותי מתאים להחלקות'
                      label="מחליקים"
                      path='/מוצרים'
                    />
                     <CardItem 
                      src="images/exmp.jpeg"
                      text='ווקס שקוף לגבות עמיד במיוחד, מכיל קראטין וחומרים המועילים לחיזוק סיב השיערה + מברשת לעיצוב הגבות'
                      label="מחליקים"
                      path='/מוצרים'
                    />
                    
                      <CardItem 
                      src="images/IMG_7024.jpeg"
                      text='
                      מחליק פלולס מגיע עד 240 מעלות עוצמתי במיוחד בעל פלטות טיטניום איכותיות'
                      label="מחליקים"
                      path='/מוצרים'
                    />
                </ul>
                
            </div>
        </div>
    </div>
  )
}

export default Cards;
