import React from 'react';
import './Footer.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faYoutube, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';


const Footer = () => {
    const [click, setClick] = useState(false);
    const closeMobileMenu = () => setClick(false);

    return (
        <div className="footer">
            <Link to='/' className="navbar-logo" onClick={closeMobileMenu}>
                <img src="/images/image_6483441_clipdrop-background-removal.png" alt="Logo" className='footer-logo-image'/>
            </Link>
            <div className="contact-section">
                <div className="contact-item">
                    <FontAwesomeIcon icon={faPhone} />
                    <a href="tel:08-686-5934" style={{fontSize:'20px',textDecoration: 'none', color: 'white'}}>08-686-5934</a>
                </div>
                <div className="contact-item">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    <a href="https://www.google.com/maps/dir/31.2519503,34.794294/straighthair+%D7%91%D7%90%D7%A8+%D7%A9%D7%91%D7%A2%E2%80%AD%E2%80%AD/@31.2525146,34.7998465,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x1502655453e7f4b5:0x27cb6426b91894d0!2m2!1d34.7716625!2d31.2506405?entry=ttu"
                        target="_blank" style={{fontSize:'20px',textDecoration: 'none', color: 'white'}}>  שדרות דוד טוביהו 125, קומה 0, גרנד קניון, באר שבע, 8424351</a>
                </div>
                <div className="social-media">
                    <a href="https://www.facebook.com/StraightHairBS"  target="_blank" className='social-media-size-icons'>
                        <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                    <a href="https://instagram.com/straighthair_beer_sheva?igshid=MmIzYWVlNDQ5Yg==" target="_blank" rel="noopener noreferrer" className='social-media-size-icons'>
                        <FontAwesomeIcon icon={faInstagram} />
                    </a>
                    <a href="https://youtube.com/@user-zx5zg1nh2n"  target="_blank" className='social-media-size-icons'>
                        <FontAwesomeIcon icon={faYoutube} />
                    </a>
                    <a href="https://www.tiktok.com/@straighthairbeersheva?_t=8eobU8hUz9b&_r=1" target="_blank" rel="noopener noreferrer" className='social-media-size-icons'>
                        <FontAwesomeIcon icon={faTiktok} />
                    </a>
                    <a href="https://wa.me/972527525806" target="_blank" rel="noopener noreferrer" className='social-media-size-icons'>
                        <FontAwesomeIcon icon={faWhatsapp} />
</a>
                </div>
            </div>
        </div>
    );
}

export default Footer;
